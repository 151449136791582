<template>
  <div>
    <Header>
      <template v-slot:title>
        {{ $t("Analysis") }}
      </template>
    </Header>
    <div class="m-5">
      <iframe
        :src="url"
        allowtransparency="true"
        border="0"
        height="1300px"
        style="border: 0"
        width="100%"
      ></iframe>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import { mapGetters } from "vuex";

export default {
  name: "Analysis",
  components: {
    Header,
  },
  data() {
    return {
      url: "",
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_profile", "get_uploaded_documents"]),
    lang() {
      let language = this.$store.getters.get_lang;
      if (language === "zh-Hant") {
        language = "zh-hant";
      }
      if (language === "zh-Hans") {
        language = "zh-hans";
      }
      return language;
    },
  },
  watch: {
    lang() {
      this.getUrl();
    },
  },
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      this.$store.dispatch("analysis_url", this.lang).then((resp) => {
        this.url = resp.data.url;
      });
    },
  },
};
</script>